import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { useSiteMetadata } from "../components/hooks/use-site-metadata"
import { useHeroImage } from "../components/hooks/useHeroImage"

import Layout from "../layouts/layout.dk"
import Image from "../components/image"
import SEO from "../components/seo"
import BackgroundImage from "gatsby-background-image"

import MainFeature from "../components/features/MainFeature.dk.jsx"
import ImageGallery from "../components/features/ImageGallery.dk.jsx"

const IndexPage = props => {
  const { title } = useSiteMetadata()
  const heroImage = useHeroImage()
  const backgroundFluidImageStack = [
    heroImage.childImageSharp.fluid,
    `linear-gradient(rgba(17, 17, 15, 0.33), rgba(47, 17, 17, 0.53))`,
  ].reverse()

  const pageData = {
    title: "Oplev med os",
    subTitle: "hygge",
    sectionTitle: "Vi elsker kage...",
    body:
      "Velkommen til Café Milch & Zucker i Rendsburg ved Kielerkanalen. Vi har åbnet igen siden den 1. maj 2021 og glæder os til at servere vores lækre hjemmelavede kager eller hjertelige morgenmad for dig. Nyd din tid hos os, og oplev hyggen i vores lille café på Paradeplatz. Med vores udendørs siddepladser kan du nyde vores kager direkte under solen med dine venner og kære.",
    seoTitle:
      "Cafe Milch und Zucker i Rendsburg ved Kielerkanalen: Nyd med os hjemmelavet kage, morgenmad og kaffe",
  }

  const formConfig = {
    id: "tina-tutorial-index",
    label: "Edit Page",
    fields: [
      {
        name: "title",
        label: "Title",
        component: "text",
      },
      {
        name: "subTitle",
        label: "Sub Title",
        component: "text",
      },
      {
        name: "sectionTitle",
        label: "Section Title",
        component: "text",
      },
      {
        name: "body",
        label: "Body",
        component: "textarea",
      },
      {
        name: "seoTitle",
        label: "SEO Title",
        component: "text",
      },
    ],
    initialValues: pageData,
    onSubmit: async () => {
      window.alert("Saved!")
    },
  }

  return (
    <Layout
      location={props.location}
      showHeader={true}
      imageData={backgroundFluidImageStack}
    >
      <SEO title={pageData.sectionTitle} />
      <MainFeature editableData={pageData} />
      <ImageGallery editableData={pageData} />
    </Layout>
  )
}

export default IndexPage
